import Placeholder from "@tiptap/extension-placeholder";
import { StarterKit } from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { ImageExtension } from "./ImageExtension";

// Default extensions configuration with image support
export const extensions = [
  StarterKit,
  ImageExtension,
  Markdown.configure({
    html: false,
    transformPastedText: true,
    transformCopiedText: true,
  }),
];

// Create extensions with options
export function createExtensions(options?: {
  disablePasteImages?: boolean;
  placeholder?: string;
}) {
  const baseExtensions = options?.disablePasteImages
    ? [
        StarterKit,
        Markdown.configure({
          html: false,
          transformPastedText: true,
          transformCopiedText: true,
        }),
      ]
    : extensions;

  if (options?.placeholder) {
    return [
      ...baseExtensions,
      Placeholder.configure({
        placeholder: options.placeholder,
        emptyEditorClass: "is-editor-empty",
      }),
    ];
  }

  return baseExtensions;
}
