import "./Editor.css";

import {
  EditorContent,
  Editor as TiptapEditor,
  useEditor,
} from "@tiptap/react";
import type { FC } from "react";
import { useEffect, useImperativeHandle } from "react";
import { twMerge } from "tailwind-merge";
import { createExtensions } from "./extensions";

interface EditorProps {
  initialContent?: string;
  value?: string;
  onChange?: (markdown: string) => void;
  editorRef?: React.RefObject<EditorHandle>;
  className?: string;
  editable?: boolean;
  disablePasteImages?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export interface EditorHandle {
  focus: () => void;
}

export const Editor: FC<EditorProps> = ({
  initialContent = "",
  value,
  onChange,
  editorRef,
  className,
  editable = true,
  disablePasteImages = false,
  placeholder = "",
  disabled = false,
}) => {
  const isControlled = value !== undefined;

  const editor = useEditor({
    extensions: disablePasteImages
      ? createExtensions({ disablePasteImages: true, placeholder })
      : createExtensions({ placeholder }),
    content: isControlled ? value : initialContent,
    onUpdate: ({ editor }: { editor: TiptapEditor }) => {
      if (onChange) {
        const markdown = editor.storage.markdown.getMarkdown();
        if (!isControlled || markdown !== value) {
          onChange(markdown);
        }
      }
    },
    editable: editable && !disabled,
    immediatelyRender: false,
  });

  // Only sync with value prop in controlled mode
  useEffect(() => {
    if (
      isControlled &&
      editor &&
      value !== editor.storage.markdown.getMarkdown()
    ) {
      editor.commands.setContent(value, false);
    }
  }, [editor, value, isControlled]);

  // Update editable state when prop changes
  useEffect(() => {
    if (editor) {
      editor.setEditable(editable && !disabled);
    }
  }, [editor, editable, disabled]);

  useImperativeHandle(
    editorRef,
    () => ({
      focus: () => {
        if (editor) {
          editor.commands.focus();
        }
      },
    }),
    [editor],
  );

  return (
    <EditorContent
      editor={editor}
      className={twMerge("editor-content prose max-w-none bg-white", className)}
    />
  );
};
