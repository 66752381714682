import Image from "@tiptap/extension-image";
import { Fragment } from "prosemirror-model";
import { Plugin, TextSelection } from "prosemirror-state";

export const ImageExtension = Image.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length;

              if (!hasFiles) {
                return false;
              }

              const images = Array.from(event.dataTransfer.files).filter(
                (file) => /image/i.test(file.type),
              );

              if (images.length === 0) {
                return false;
              }

              event.preventDefault();

              const { schema } = view.state;
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              if (!coordinates) return false;

              let insertPos = coordinates.pos;

              images.forEach((image) => {
                const reader = new FileReader();

                reader.onload = (readerEvent) => {
                  if (!readerEvent.target?.result) return;

                  const imageNode = schema.nodes.image.create({
                    src: readerEvent.target.result,
                  });
                  const paragraphNode = schema.nodes.paragraph.create();

                  // Create fragment with image followed by paragraph
                  const fragment = Fragment.from([imageNode, paragraphNode]);

                  // Insert both nodes
                  let tr = view.state.tr.insert(insertPos, fragment);

                  // Move cursor into the paragraph node
                  const newPos = insertPos + imageNode.nodeSize + 1;
                  tr = tr.setSelection(TextSelection.create(tr.doc, newPos));
                  tr = tr.scrollIntoView();

                  view.dispatch(tr);

                  // Ensure cursor is visible at the bottom
                  setTimeout(() => {
                    const selection = view.domAtPos(newPos);
                    if (selection?.node instanceof HTMLElement) {
                      selection.node.scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                      });
                    }
                  }, 0);

                  // Update position for next image
                  insertPos = newPos + 1;
                };
                reader.readAsDataURL(image);
              });

              return true;
            },
            paste(view, event) {
              const hasFiles =
                event.clipboardData &&
                event.clipboardData.files &&
                event.clipboardData.files.length;

              if (!hasFiles) {
                return false;
              }

              const images = Array.from(event.clipboardData.files).filter(
                (file) => /image/i.test(file.type),
              );

              if (images.length === 0) {
                return false;
              }

              event.preventDefault();

              const { schema } = view.state;
              let insertPos = view.state.selection.from;

              images.forEach((image) => {
                const reader = new FileReader();

                reader.onload = (readerEvent) => {
                  if (!readerEvent.target?.result) return;

                  const imageNode = schema.nodes.image.create({
                    src: readerEvent.target.result,
                  });
                  const paragraphNode = schema.nodes.paragraph.create();

                  // Create fragment with image followed by paragraph
                  const fragment = Fragment.from([imageNode, paragraphNode]);

                  // Replace selection with both nodes
                  let tr = view.state.tr.replaceWith(
                    insertPos,
                    insertPos,
                    fragment,
                  );

                  // Move cursor into the paragraph node
                  const newPos = insertPos + imageNode.nodeSize + 1;
                  tr = tr.setSelection(TextSelection.create(tr.doc, newPos));
                  tr = tr.scrollIntoView();

                  view.dispatch(tr);

                  // Ensure cursor is visible at the bottom
                  setTimeout(() => {
                    const selection = view.domAtPos(newPos);
                    if (selection?.node instanceof HTMLElement) {
                      selection.node.scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                      });
                    }
                  }, 0);

                  // Update position for next image
                  insertPos = newPos + 1;
                };
                reader.readAsDataURL(image);
              });

              return true;
            },
          },
        },
      }),
    ];
  },
});
